<template>
    <!-- 内容 -->
    <div class="index-detail">
        <div class="detail-content">
            <div class="detail-title">
                <div class="detail-title-left">
                    <span>{{ title }}</span>
                    <div class="detail-title-left-bottom">
                        <span>{{ releasetime_text }}</span>
                        <span style="    padding-left: 50px;">点击次数：{{ views }}</span>
                    </div>
                </div>
                <div class="detail-title-back" @click="back">返回</div>
            </div>

            <!-- 富文本 -->
            <p v-html="content">富文本</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'newDetail',
    data(){
        return{
           title:"",//标题
           describe:"",//简介
           content:"",//内容
           releasetime_text:"",//发布时间
           views:"",//点击数
           image:""//照片
        }
    },
    created() {
        this.getList()
    },
    methods: {
        back() {
            this.$router.back()
        },
        getList(){
            this.$request({
                url:'/api/article/teacher1_detail',
                method:'POST',
                data:{
                   id:this.$route.query.id
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'风采详情')
                    this.title=res.data.info.title//标题
                    this.content=res.data.info.content//内容
                    this.describe=res.data.info.describe//简介
                    this.releasetime_text=res.data.info.releasetime_text//发布时间
                    this.views=res.data.info.views//点击数
                    this.image=res.data.info.image//照片
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .index-detail{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #F6F6EA;
    }
    // 内容
    .detail-content{
        width: 1200px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 60px 40px;
    }
    .detail-title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
    .detail-title-left{
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 20px;
    }
    .detail-title-left-bottom{
        color: #999999;
        font-size: 12px;
        padding-top: 12px;
        display: flex;
    }
    // 返回
    .detail-title-back{
        padding: 4px 16px;
        color: #ffffff;
        background: #26716C;
        height: 30px;
        cursor: pointer;
    }
    // 底部翻页
    .detail-bottom{
        margin-top: 24px;
        padding-top: 20px;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
    .prev, .next{
        font-size: 16px;
        color: #656565;
    }
    .next{
        padding-top: 12px;
    }
</style>